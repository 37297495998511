import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Button, CommonLoader } from "../../components";
import Notification from '../../components/Notification/Notification';
import CommonModal from '../../components/modal/CommonModal';
import { Images } from '../../config';
import { Constanttitle, GetDateWithMyFormat, getItemStore, IsEmpty, storageKeys } from '../../config/appConstant';
import PageSubTitle from '../PageSubTitle/PageSubTitle';
import './CustomCalender.css';
import Style from "./CustomCalenderStyle";

const CustomCalendar = (props) => {
    const { showHeader = true, HeaderData } = props; // Destructure showHeader prop with default value true
    const TenantConfiguration = JSON.parse(getItemStore(storageKeys.TenantConfiguration));

    const DAY = new Date();
    const MonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [MonthData, setWeekDay] = React.useState([{ "ID": 1, "Name": "Sunday" }, { "ID": 2, "Name": "Monday" }, { "ID": 3, "Name": "Tuesday" }, { "ID": 4, "Name": "Wednesday" }, { "ID": 5, "Name": "Thursday" }, { "ID": 6, "Name": "Friday" }, { "ID": 7, "Name": "Saturday" }]);
    const [AllCalenderData, setAllCalenderData] = React.useState(props?.calenderData)
    const [IsLoading, setIsLoading] = React.useState(true);
    const [CurrentMonth, setCurrentMonth] = React.useState(DAY.getMonth() + 1);
    const [CurrentYear, setCurrentYear] = React.useState(DAY.getFullYear());
    const [CurrentMonthName, setCurrentMonthName] = React.useState(MonthNames[DAY.getMonth()]);
    const [TotalDayOfMonth, setTotalDayOfMonth] = React.useState([]);
    const [TotalCards, setTotalCards] = React.useState([]);
    const [firstDateWeek, setfirstDateWeek] = React.useState(new Date(DAY.getFullYear(), DAY.getMonth(), 1).getDay() + 1);
    const [BlurCards, setBlurCards] = React.useState(0);// for Card Color Blure purpose

    useEffect(() => {
        GetCurrntMonth('First')
    }, [])

    useEffect(() => {
        setIsLoading(props?.isLoading)
        setAllCalenderData(props?.calenderData)
        // setTimeout(() => {
        //     setIsLoading(false)
        // }, 1500);
    }, [props?.calenderData])

    useEffect(() => {
        if (props.ChanngeProps == 1) {
            // OnPressNext()
            nextMonth(new Date(), CurrentMonth, CurrentYear)
            props.handleChangeProps(-1, (MonthNames[CurrentMonth]), CurrentYear)
        }
        else if (props.ChanngeProps == 2) {
            // OnPressPrevious()
            previousMonth(new Date(), CurrentMonth, CurrentYear)
            props.handleChangeProps(-1, (MonthNames[CurrentMonth]), CurrentYear)

        }
    }, [props?.ChanngeProps])

    const GetCurrntMonth = (val) => {
        setIsLoading(true)
        var pairs = [];
        for (let i = 0; i < new Date(DAY.getFullYear(), DAY.getMonth() + 1, 0).getDate(); i++) {
            pairs.push({ Date: i + 1 });
        }
        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(DAY.getFullYear(), DAY.getMonth(), 1);
        let lastDay = new Date(DAY.getFullYear(), DAY.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx

        for (let i = 0; i < TotalDaysOfMonth; i++) {
            // new Date().getDate() == TotalCards[i].Date
            cards.push({ ID: i + 1, PreviewURL: 'https://cdn.mybauschreach.com/Images/AutomationAssets/30ad2c7-88d2-5f5-83f3-44706c55bfbc_thumb.png?v=1698642914' });
        }
        setTotalCards(cards)
        setBlurCards(new Date().getMonth() <= DAY.getMonth() ? Indexxx + new Date().getDate() - 1 : 0)
        setTotalDayOfMonth(pairs)
        setIsLoading(false)
        //-------------------------------------------------------------------
        { val != 'First' && props.handleCalendar(GetDateWithMyFormat(firstDay), GetDateWithMyFormat(lastDay)) }//fetch date from Api -Call Api
    }

    const nextMonth = (date, currentMonth, currentYear) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth + 1)
        var current = ''
        if (currentMonth == 11) {
            current = new Date(date.getFullYear() + 1, -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }
        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }

        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        for (let i = 0; i < TotalDaysOfMonth; i++) {
            cards.push({ ID: i + 1 });
        }
        setTotalCards(cards)
        // setBlurCards(new Date().getMonth() == current.getMonth() ? Indexxx + new Date().getDate() - 1 : new Date().getMonth() < current.getMonth() ? Indexxx : TotalDaysOfMonth)
        setBlurCards(
            new Date().getFullYear() == current.getFullYear() ?
                (new Date().getMonth() == current.getMonth() ? Indexxx + new Date().getDate() - 1
                    : new Date().getMonth() < current.getMonth() ? Indexxx : TotalDaysOfMonth)
                : new Date().getFullYear() < current.getFullYear() ? Indexxx : TotalDaysOfMonth
        );
        //-----------------------------------------------------------------
        // props.handleCalendar(GetCMMSDateWithMyFormat(firstDay), GetCMMSDateWithMyFormat(lastDay))//fetch date from Api -Call Api
        setTotalDayOfMonth(pairs)
        props.GetFilterMonthData(current.getMonth() + 1, current.getFullYear())
        // setIsLoading(false)
    }

    const previousMonth = (date, currentMonth, currentYear) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth - 1)
        var current = ''
        if (currentMonth == 1) {
            current = new Date(date.getFullYear(), -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth - 2, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }

        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }

        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        for (let i = 0; i < TotalDaysOfMonth; i++) {
            cards.push({ ID: i + 1 });
        }

        setTotalCards(cards)
        // setBlurCards(new Date().getMonth() == current.getMonth() ? Indexxx + new Date().getDate() - 1 : new Date().getMonth() < current.getMonth() ? Indexxx : TotalDaysOfMonth)
        setBlurCards(
            new Date().getFullYear() == current.getFullYear() ?
                (new Date().getMonth() == current.getMonth() ? Indexxx + new Date().getDate() - 1
                    : new Date().getMonth() < current.getMonth() ? Indexxx : TotalDaysOfMonth)
                : new Date().getFullYear() < current.getFullYear() ? Indexxx : TotalDaysOfMonth
        );
        //--------------------------------------------------------------------
        // props.handleCalendar(GetCMMSDateWithMyFormat(firstDay), GetCMMSDateWithMyFormat(lastDay))//fetch date from Api -Call Api
        setTotalDayOfMonth(pairs)
        props.GetFilterMonthData(current.getMonth() + 1, current.getFullYear())
        // setIsLoading(false)
    }

    const OnPressNext = () => {
        const currentYear = new Date().getFullYear();
        if (CurrentYear == currentYear && CurrentMonth < 12) {
            // nextMonth(new Date(), CurrentMonth, CurrentYear)
        }
    }
    const OnPressPrevious = () => {
        const currentYear = new Date().getFullYear();
        if (CurrentYear == currentYear && CurrentMonth > 1) {
            // previousMonth(new Date(), CurrentMonth, CurrentYear)
        }
    }

    const transformDeleteData = (specificIndex) => {
        const newArray = [...AllCalenderData];
        const assetsArray = newArray[specificIndex].Assets;
        assetsArray.forEach(asset => {
            asset.Status = 3;
        });
        newArray[specificIndex].Assets = assetsArray;

        setAllCalenderData(newArray)
        setOpenPopup(false)
    }

    const [OpenPopup, setOpenPopup] = useState(false)
    const [DeleteIndex, setDeleteIndex] = useState("");

    const handleDelete = () => {
        transformDeleteData(DeleteIndex)
    }
    const handleClose = () => {
        setOpenPopup(false)
    }

    const LeftDataClick = (e, index) => {
        if (index == 0 && !IsEmpty(HeaderData.TutorialLink)) {
            window.open(HeaderData.TutorialLink)
        }
    }

    const [ShowNotification, setShowNotification] = useState(false);
    const [Message, setMessage] = useState('');
    const [ToastType, setToastType] = useState('');
    const handleNotification = (val, msg, type) => {
        setShowNotification(val);
        setMessage(msg)
        setToastType(type);
    }
    const { classes } = props;

    const isFutureDate = (DateValue, AddDays) => {
        const targetDate = new Date(DateValue);

        // If AddDays is not provided, return whether the target date is in the future
        if (AddDays == "" || AddDays == undefined || AddDays == null) {
            return new Date().getTime() > targetDate.getTime();
        } else {
            // Add the specified number of days to the current date
            const futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + AddDays);

            // Check if the future date is less than the target date
            return futureDate.getTime() < targetDate.getTime();
        }
    };

    const addDays = (date, days) => {
        date.setDate(date.getDate() + days);
        return date.toISOString().split('T')[0];
    }

    const filterStatus = (AssetData) => {
        if (IsEmpty(AssetData)) {
            return []
        } else {
            return AssetData.filter((item) => item.Status != 3)
        }
    }

    return (
        <>
            {showHeader
                ? <div className='customcalender-main' >
                    <div className='customcalender-submain' >
                        <div className='customcalender-pagesubtitle' >
                            <PageSubTitle
                                isRightButton1={false}
                                RightButtonText2={"Add Post"}
                                RightButtonClick2={() => props.handlePopup(true, "Add", "Add Social Post", [], addDays(DAY, TenantConfiguration[0]?.ad))}
                                CenterRightClick={() => nextMonth(new Date(), CurrentMonth, CurrentYear)}
                                CenterLeftClick={() => previousMonth(new Date(), CurrentMonth, CurrentYear)}
                                CenterContentText={CurrentMonthName + " " + CurrentYear}
                                LeftDataClick={LeftDataClick.bind(this)}
                                LeftData={[
                                    { icon: Images.Play_IC, cursorPointer: !IsEmpty(HeaderData.TutorialLink), isDrop: false, tooltip: Constanttitle.videoTutorial, ShowTutorial: !IsEmpty(HeaderData.TutorialLink) },
                                    { icon: Images.lead_gen_ad, isDrop: false, tooltip: Constanttitle.leadGenTutorial, ShowTutorial: true },
                                    { icon: Images.multiple_posts, isDrop: false, tooltip: Constanttitle.multiplePostsTutorial, ShowTutorial: true },
                                    { icon: Images.facebook_teal, isDrop: false, tooltip: Constanttitle.facebookTutorial, ShowTutorial: true },
                                    { icon: Images.instagram_teal, isDrop: false, tooltip: Constanttitle.instagramTutorial, ShowTutorial: true },
                                ]} />
                        </div>
                    </div>
                </div> : <></>}


            <div className='customcalender-daynamecontent' >
                <div className='customcalender-dayname' >
                    {MonthData.map((Item, value) => {
                        return (
                            <div key={value} id={!showHeader ? 'CalenderTopTitleContentView-day-location' : 'CalenderTopTitleContentView-day'} className={classes.CalenderTopTitleContentView} >{Item.Name}</div>
                        )
                    })}
                </div>
                <div className='customcalender-date'>
                    {IsLoading ? <CommonLoader /> :
                        TotalCards.map((item, index) => {
                            // name == CurrentMonthName && new Date().getDate() == CalenderDate ? classes.CalenderCurrentContent :
                            const calenderContentId = index < BlurCards ? "calender-content-view-gray" : "calender-content-view-white";
                            const calenderContentIdbyLocation = index < BlurCards ? "calender-content-view-gray-Location" : "calender-content-view-white-Location";
                            return (
                                <div key={item.id} className={classes.CalenderContentView} id={!showHeader ? calenderContentIdbyLocation : calenderContentId} >
                                    {AllCalenderData?.map((Item, Index) => {
                                        let CalenderDate = Item.DateOfCreation.split('-')[2]
                                        let CalenderFullDate = moment(`${CurrentYear}-${CurrentMonthName}-${CalenderDate}`, "YYYY-MMMM-DD").format("YYYY-MM-DD");
                                        return (
                                            Index == index - firstDateWeek + 1 &&
                                            <div key={Index}>
                                                <div className='customcalender-mapincss'>
                                                    <div className={classes.CalenderInnerContent}>{CalenderDate}</div>
                                                    <div className='customcalender-empty-width'>
                                                        {Item.Assets.length && Item.Assets[0].IsLeadGenPost == 1 && filterStatus(Item.Assets).length ? <img style={{ height: '18px', marginRight: '5px' }} src={Images.lead_gen_ad} /> : <></>}
                                                        {filterStatus(Item.Assets).length > 1 && <img style={{ height: '20px' }} src={Images.multiple_posts} />}
                                                    </div>
                                                </div>
                                                {/* {alert(Item.DateOfCreation + CalenderFullDate)} */}
                                                <Divider className='customcalender-divider' />
                                                {(CalenderFullDate == Item.DateOfCreation && Item.Assets != '' && filterStatus(Item.Assets).length)
                                                    ? <div className={!showHeader ? 'content-box-calendar-location' : 'content-box-calendar'}>
                                                        <div className={!showHeader ? 'box-image-calendar calendar-image-box-Location' : 'box-image-calendar calendar-image-box'}
                                                            style={{ backgroundImage: `url(${!IsEmpty(Item.Assets[0]) && Item.Assets[0].PreviewURL})` }}>
                                                            {props.isButtonShow && <div className='button-hover'>
                                                                <div className='button-field-calendar'><Button round Medium type={3} title='Preview' onClick={() => props.handlePopup(true, "Preview", "View Social Post", Item.Assets)} /></div>
                                                                {!isFutureDate(CalenderFullDate) && <div className='button-field-calendar'><Button round Medium type={3} title='Edit' onClick={() => props.handlePopup(true, "Edit", "Reschedule Post", Item.Assets)} /></div>}
                                                                {isFutureDate(CalenderFullDate, TenantConfiguration[0]?.ad - 1) && <div className='button-field-calendar'><Button round Medium type={3} title='Add Post' onClick={() => { props.handlePopup(true, "Add", "Add Social Post", [], Item.DateOfCreation) }} /></div>}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    : props.isButtonShow && isFutureDate(CalenderFullDate, TenantConfiguration[0]?.ad - 1) &&
                                                    <div className="content-box-calendar">
                                                        <div className='button-hover'>
                                                            <div className='button-field-calendar'><Button round Medium type={3} title='Add Post' onClick={() => props.handlePopup(true, "Add", "Add Social Post", [], Item.DateOfCreation)} /></div>
                                                        </div>
                                                    </div>}
                                            </div>)
                                    })}
                                </div>)
                        })
                    }
                </div>
            </div>
            {OpenPopup && <CommonModal
                maxWidth={'sm'}
                Title={"Delete Post"}
                ActionText={"Delete"}
                IsDeletePopup={true}
                onClose={handleClose.bind(this)}
                onSave={handleDelete.bind(this)} IsScroll >
            </CommonModal>}
            {ShowNotification &&
                <Notification
                    handleClose={handleNotification.bind(this)}
                    message={Message}
                    variant={ToastType}
                />}
        </>
    )
}

export default withStyles(Style)(CustomCalendar);