import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import * as APIController from "../../API/APIController";
import { clsDeleteDocument, clsGetDocumentForDropdown } from '../../API/APIParameters';
import { Text } from '../../components';
import { DOCUMENTS, FileExtension, IsEmpty } from '../../config/appConstant';
import './UploadAssets.css';
import { Images } from "../../config";
import { URLs } from '../../API/URLs';

const DocumentObject = DOCUMENTS
const UploadAssets = (props) => {

    let ApiObject = {
        "ID": 0,
        "DocumentTypeID": "1",
        "ObjectID": "", // for Static boject ID 'FCD7F991-19DD-4FA0-A079-D1A35B4DED70'
        "file": '',
        "DocumentURL": '',
        "DocumentName": '',
        "MembershipID": '',
        "TenantID": '',
        "Description": '',
        "SequenceNo": '',
        "IsByAdmin": ''
    }
    const { isMultipleAdded, customClass, ObjectID, IsMediaLibrary, IsByAdmin, formvalue, Label, IsUploadSelect, TenantID, IsEdit, IsSavedClick, MembershipID, DocumentTypeID, onClose, isImageDeleted } = props
    const [isDragging, setIsDragging] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [isLoadData, setIsLoadData] = useState(true);
    const fileInputRef = useRef(null);
    const [SequenceNo, setSequenceNo] = useState(0);
    const [UploadObject, setUploadObject] = useState(ApiObject);
    const [Documents, setDocuments] = useState(DOCUMENTS);
    const [InitialState, setInitialState] = useState({ Attachments: [] })


    useEffect(() => {
        if (IsEdit) {
            GetDocumentForDropdown(ObjectID, DocumentTypeID)
        }
        else {
            setIsLoadData(false)
        }
    }, [])


    useEffect(() => {
        let NoOfuploadImages = Documents.filter((item) => item.DocumentURL != '')
        IsUploadSelect(NoOfuploadImages)
    }, [Documents]);



    useEffect(() => {
        if (!IsEmpty(ObjectID) && !IsEmpty(DocumentTypeID) && IsSavedClick == 1) {
            SyncAtttachments(ObjectID, DocumentTypeID,)
        }
    }, [props])



    const handleFileInputChange = (e) => {
        e.preventDefault();
        let UUID = uuidv4();
        let idx = SequenceNo
        const res = e.target.files[0];
        const imageUrl = URL.createObjectURL(res);
        const DocumentName = UUID + '.' + res.name.split('.').pop().toLowerCase();
        let Temp = Documents.map((item, index) => index !== idx ? item : { ...item, DocumentURL: imageUrl, file: res, DocumentName: DocumentName, SequenceNo: idx })
        isMultipleAdded && Temp.push({ ...DOCUMENTS[0], SequenceNo: DOCUMENTS[0].SequenceNo + idx })
        setInitialState({ ...InitialState, Attachments: Temp })
        setDocuments(Temp.reverse())
        fileInputRef.current.value = '';
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };
    const handleDragLeave = () => {
        setIsDragging(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        let UUID = uuidv4();
        let idx = SequenceNo
        const res = e.dataTransfer.files[0];
        const imageUrl = URL.createObjectURL(res);
        const DocumentName = UUID + '.' + res.name.split('.').pop().toLowerCase();
        let Temp = Documents.map((item, index) => index !== idx ? item : { ...item, DocumentURL: imageUrl, file: res, DocumentName: DocumentName, SequenceNo: idx })
        isMultipleAdded && Temp.push({ ...DOCUMENTS[0], SequenceNo: DOCUMENTS[0].SequenceNo + idx })
        setInitialState({ ...InitialState, Attachments: Temp })
        setDocuments(Temp.reverse())
        fileInputRef.current.value = '';

    };
    const handleButtonClick = (no) => {
        setSequenceNo(no)
        fileInputRef.current.click();

    };

    const clearObject = () => {
        setUploadObject(ApiObject)
    }
    const handleDeleteClick = (idx) => {
        DeleteDocument(idx, Documents[idx])
    };


    const LocalDocumetDelete = (idx) => {
        setDocuments(Documents.filter((_, index) => index !== idx));
        !isMultipleAdded && setDocuments(DOCUMENTS)
    }

    const getFileExtension = (item, index) => {
        const parts = item.DocumentName.split(".");
        const extension = '.' + parts[parts.length - 1];
        switch (extension) {
            case FileExtension.TypeCSV:
                return Images.csv
            case FileExtension.TypeZIP:
                return Images.zip
            case FileExtension.TypeHTML:
                return Images.html
            case FileExtension.TypeMP4:
                return Images.video
            case FileExtension.TypePDF:
                return Images.pdf
            case FileExtension.TypeSVG:
                return Documents[index].DocumentURL
            case FileExtension.TypePNG:
                return Documents[index].DocumentURL
            case FileExtension.TypeJPG:
                return Documents[index].DocumentURL
            case FileExtension.TypeJPEG:
                return Documents[index].DocumentURL
            case FileExtension.TypeGIF:
                return Documents[index].DocumentURL
            default:
                return Images.Profileimg;
        }

    }

    const DocumentImageItem = ({ item, index }) => {
        if (item.DocumentURL == '') {
            return (
                <div>
                    <div
                        onClick={() => handleButtonClick(index)}
                        className={` ${customClass} container-UploadAssets ${isDragging ? 'drag-over' : ''}`}
                        onDragOver={handleDragOver}
                        onDragStartCapture={() => setSequenceNo(index)}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}>
                        <input
                            key={SequenceNo}
                            type="file" accept=".csv, .png, .jpg, .html, .zip, .mp4, .svg,.jpeg" ref={fileInputRef}
                            onChange={handleFileInputChange}
                            style={{ display: 'none' }} />
                        <Text headline mainButtonColor>{isDragging ? 'Drop Here' : 'Upload Image'}</Text>
                    </div>
                    <Text caption1 semibold grayColor>{`* ${Label}`}</Text>

                    {/* {error || document.length &&  */}
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className={`${customClass} container-UploadAssets image-container-UploadAssets`} >
                        <FaTimes className="delete-icon-UploadAssets" onClick={() => handleDeleteClick(index)} />
                        <img src={getFileExtension(item, index)} alt='image' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    </div>
                    <Text caption1 semibold grayColor>{`* ${Label}`}</Text>
                </div>
            );
        }
    };

    const DeleteDocument = (idx, item) => {
        if (IsEmpty(item.ID)) {
            setDocuments(Documents.filter((_, index) => index !== idx));
            !isMultipleAdded && setDocuments(DOCUMENTS)
            clearObject()
        } else {
            const objParameter = new clsDeleteDocument();
            objParameter.ID = item.ID;
            APIController.DeleteDocument(objParameter)
                .then((response) => {
                    setDocuments(Documents.filter((_, index) => index !== idx));
                    !isMultipleAdded && setDocuments(DOCUMENTS)
                    clearObject()
                    // isImageDeleted(true)
                })
        }
    }

    const GetDocumentForDropdown = (EditID, DocumentTypeID) => {
        const objParameter = new clsGetDocumentForDropdown();
        objParameter.ObjectID = IsEmpty(IsMediaLibrary) ? EditID : null;
        objParameter.ID = !IsEmpty(IsMediaLibrary) ? EditID : null;
        objParameter.DocumentTypeID = DocumentTypeID;
        APIController.GetDocumentForDropdown(objParameter)
            .then((response) => {
                if (!IsEmpty(response.data)) {
                    { isMultipleAdded ? HandleMultiImage(response) : HandlesingleImage(response) }
                }
                else {
                    setDocuments(DOCUMENTS)
                    setIsLoadData(false)
                }
            })
    }


    const HandlesingleImage = (response) => {
        let Temp = []
        let data = (response.data[0])
        UploadObject.DocumentURL = data.URL
        UploadObject.ObjectID = data.ObjectID
        UploadObject.ID = IsEmpty(data.ID) ? 0 : data.ID
        Temp.push({ DocumentURL: UploadObject.DocumentURL, DocumentName: data.Name, ID: data.ID })
        setDocuments(Temp)
        setIsLoadData(false)
    }
    const HandleMultiImage = (response) => {
        let Temp = []
        let dataDocument = response.data
        Temp.push(DOCUMENTS[0])
        dataDocument.map((item) => {
            Temp.push({ DocumentURL: item.URL, DocumentName: item.Name, ID: item.ID })
        })
        setDocuments(Temp)
        setIsLoadData(false)

    }

    const SyncAtttachments = async (ObjectID, DocumentTypeID) => {
        for (let i = 0; i < InitialState.Attachments.length; i++) {
            if (!IsEmpty(InitialState.Attachments[i].file)) {
                UploadObject.file = InitialState.Attachments[i].file
                UploadObject.DocumentName = InitialState.Attachments[i].DocumentName
                UploadObject.ObjectID = ObjectID
                UploadObject.DocumentTypeID = DocumentTypeID
                UploadObject.MembershipID = !IsEmpty(MembershipID) ? MembershipID : ''
                UploadObject.TenantID = !IsEmpty(TenantID) ? TenantID : null
                UploadObject.IsByAdmin = !IsEmpty(IsByAdmin) ? IsByAdmin : 0
                UploadObject.Description = !IsEmpty(IsByAdmin) ? formvalue.Description : ''
                UploadObject.MembershipID = !IsEmpty(IsByAdmin) ? formvalue.MembershipID : ''
                UploadObject.SequenceNo = !IsEmpty(IsByAdmin) ? formvalue.SequenceNo : ''
                if (InitialState.Attachments[i].Sync == 0) {
                    await UploadExcel(i)
                }
            }
            if (InitialState.Attachments.length - 1 == i) {
                setInitialState({ ...InitialState, Attachments: [] })
                setDocuments(DOCUMENTS)
                setisLoading(false)
                if (!isMultipleAdded) {
                    setTimeout(() => {
                        !isMultipleAdded && onClose(1)
                    }, 500);
                }
            }

        }

    }
    const UploadExcel = async (i) => {
        setisLoading(true)
        const formData = new FormData();
        formData.append('file', UploadObject.file);
        formData.append('DocumentName', UploadObject.DocumentName);
        formData.append('DocumentTypeID', UploadObject.DocumentTypeID);
        formData.append('ObjectID', UploadObject.ObjectID);
        formData.append('ID', UploadObject.ID);
        formData.append('MembershipID', UploadObject.MembershipID);
        formData.append('TenantID', UploadObject.TenantID);
        formData.append('Description', UploadObject.Description);
        formData.append('SequenceNo', UploadObject.SequenceNo);
        formData.append('IsByAdmin', UploadObject.IsByAdmin);
        const xhr = new XMLHttpRequest();
        const token = localStorage.getItem("Access_Token")
        const uploadUrl = URLs.AddEditDocument;
        xhr.open('POST', uploadUrl, true);
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                let response = JSON.parse(xhr.responseText)
                if (xhr.status === 200) {

                    if (response.status == 1) {
                        const newState = InitialState.Attachments.map((obj, index) =>
                            index == i ? { ...obj, Sync: 1 } : obj
                        );
                        setInitialState({ ...InitialState, Attachments: newState })
                    }
                    else {
                        const newState = InitialState.Attachments.map((obj, index) =>
                            index == i ? { ...obj, Sync: 0 } : obj
                        );
                        setInitialState({ ...InitialState, Attachments: newState })
                    }
                }
                else {
                    setisLoading(false)
                    alert(response.message)
                }
                // !isMultipleAdded && onClose()
            }

        };
        xhr.send(formData);
    };


    return (
        <>
            {!isLoadData &&
                <div className='maincontainer-UploadAssets'>
                    <div className='maincontainer-UploadAssets-flex'>
                        <div className='maincontainer-UploadAssets-Document'>
                            {Documents.map((item, index) => (
                                <DocumentImageItem index={index} item={item} key={item.id} />

                            ))
                            }
                        </div>
                    </div>
                    {/* <Button loading={isLoading} onClick={() => SyncAtttachments(ObjectID, DocumentTypeID)} disabled={(!isMultipleAdded && Documents[0].DocumentURL == '') ? true : (isMultipleAdded && Documents.length == 1) ? true : false} round Medium title={'Upload'}></Button> */}
                </div>}
        </>
    );
};


UploadAssets.propTypes = {
    customClass: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isMultipleAdded: PropTypes.bool,
    error: PropTypes.bool,
    IsSavedClick: PropTypes.bool,
    onClose: PropTypes.func,
    IsUploadSelect: PropTypes.func,
    Label: PropTypes.string,
    TenantID: PropTypes.string,
};

UploadAssets.defaultProps = {
    customClass: {},
    onClose: () => { },
    IsUploadSelect: () => { },
    isMultipleAdded: false,
    TenantID: null,
    error: false,
    Label: '',
    IsSavedClick: false,
};
export default UploadAssets;