import PropTypes from "prop-types";
import React from "react";
import {
  BaseColor,
  FontFamily,
  FontWeight,
  Typography,
  lineHeight,
} from "../../config";

export default function Index(props) {
  const {
    header,
    heading,
    title1,
    title,
    title2,
    redColor,
    title3,
    title4,
    headline,
    body1,
    body2,
    callout,
    subhead,
    footnote,
    caption1,
    caption2,
    overline,
    thin,
    ultraLight,
    light,
    regular,
    medium,
    semibold,
    bold,
    heavy,
    black,
    grayColor,
    dividerColor,
    whiteColor,
    ThirdButtonColor,
    SecoundButtonColor,
    BlackTextColor,
    garycardcolor,
    skycolor,
    mainButtonColor,
    bluetext,
    emaillinkcolor,
    fieldColor,
    blackColor,
    hometilesubtext,
    hometilemenhead,
    fontgreendark,
    greenColor,
    textAlign,
    style,
    children,
    uppercase,
    capitalize,
    lowercase,
    lineheightreguler,
    lineheightmedium,
    lineheighthigh,
    darkgreen,
    lightpurplecolor,
    lineheightxsmall,
    inputErrorColor,
    mainButtonHoverColor,
    inputLableColor,
  } = props;

  let textStyle = {
    fontFamily: FontFamily.FontFamily_1,
    textAlign: textAlign,
    ...(header ? Typography.header : null),
    ...(heading ? Typography.heading : null),
    ...(title1 ? Typography.title1 : null),
    ...(title ? Typography.title : null),
    ...(title2 ? Typography.title2 : null),
    ...(title3 ? Typography.title3 : null),
    ...(title4 ? Typography.title4 : null),
    ...(headline ? Typography.headline : null),
    ...(body1 ? Typography.body1 : null),
    ...(body2 ? Typography.body2 : null),
    ...(callout ? Typography.callout : null),
    ...(subhead ? Typography.subhead : null),
    ...(footnote ? Typography.footnote : null),
    ...(caption1 ? Typography.caption1 : null),
    ...(caption2 ? Typography.caption2 : null),
    ...(overline ? Typography.overline : null),
    ...(thin ? { fontWeight: FontWeight.thin } : null),
    ...(ultraLight ? { fontWeight: FontWeight.ultraLight } : null),
    ...(light ? { fontWeight: FontWeight.light } : null),
    ...(regular ? { fontWeight: FontWeight.regular } : null),
    ...(medium ? { fontWeight: FontWeight.medium } : null),
    ...(semibold ? { fontWeight: FontWeight.semibold } : null),
    ...(bold ? { fontWeight: FontWeight.bold } : null),
    ...(heavy ? { fontWeight: FontWeight.heavy } : null),
    ...(black ? { fontWeight: FontWeight.black } : null),
    ...(uppercase ? { textTransform: "uppercase" } : null),
    ...(capitalize ? { textTransform: "capitalize" } : null),
    ...(lowercase ? { textTransform: "lowercase" } : null),
    ...(lineheightreguler ? { lineHeight: lineHeight.regular } : null),
    ...(lineheightmedium ? { lineHeight: lineHeight.medium } : null),
    ...(lineheighthigh ? { lineHeight: lineHeight.height } : null),
    ...(lineheightxsmall ? { lineHeight: lineHeight.xsmall } : null),
    ...(grayColor ? { color: BaseColor.grayColor } : null),
    ...(dividerColor ? { color: BaseColor.dividerColor } : null),
    ...(whiteColor ? { color: BaseColor.whiteColor } : null),
    ...(fontgreendark ? { color: BaseColor.fontgreendark } : null),
    ...(greenColor ? { color: BaseColor.greenColor } : null),
    ...(bluetext ? { color: BaseColor.bluetext } : null),
    ...(fieldColor ? { color: BaseColor.fieldColor } : null),
    ...(blackColor ? { color: BaseColor.blackColor } : null),
    ...(hometilesubtext ? { color: BaseColor.hometilesubtext } : null),
    ...(hometilemenhead ? { color: BaseColor.hometilemenhead } : null),
    ...(ThirdButtonColor ? { color: BaseColor.ThirdButtonColor } : null),
    ...(mainButtonColor ? { color: BaseColor.mainButtonColor } : null),
    ...(SecoundButtonColor ? { color: BaseColor.SecoundButtonColor } : null),
    ...(BlackTextColor ? { color: BaseColor.BlackTextColor } : null),
    ...(garycardcolor ? { color: BaseColor.garycardcolor } : null),
    ...(skycolor ? { color: BaseColor.skycolor } : null),
    ...(redColor ? { color: BaseColor.redColor } : null),
    ...(emaillinkcolor ? { color: BaseColor.emaillinkcolor } : null),
    ...(darkgreen ? { color: BaseColor.darkgreen } : null),
    ...(lightpurplecolor ? { color: BaseColor.lightpurplecolor } : null),
    ...(inputErrorColor ? { color: BaseColor.inputErrorColor } : null),
    ...(mainButtonHoverColor ? { color: BaseColor.mainButtonHoverColor } : null),
    ...(inputLableColor ? { color: BaseColor.inputLableColor } : null),
    ...(style ? style : null),
  };

  return (
    <span style={textStyle}>{children}</span>
    // <span  >
    //   {children}
    // </span>
  );
}

Index.propTypes = {
  header: PropTypes.bool,
  heading: PropTypes.bool,
  title1: PropTypes.bool,
  title2: PropTypes.bool,
  title3: PropTypes.bool,
  title4: PropTypes.bool,
  headline: PropTypes.bool,
  body1: PropTypes.bool,
  body2: PropTypes.bool,
  callout: PropTypes.bool,
  subhead: PropTypes.bool,
  footnote: PropTypes.bool,
  caption1: PropTypes.bool,
  caption2: PropTypes.bool,
  overline: PropTypes.bool,
  thin: PropTypes.bool,
  ultraLight: PropTypes.bool,
  light: PropTypes.bool,
  regular: PropTypes.bool,
  medium: PropTypes.bool,
  mediumsemibold: PropTypes.bool,
  lineheighthigh: PropTypes.bool,
  lineheightmedium: PropTypes.bool,
  lineheightreguler: PropTypes.bool,
  lineheightxsmall: PropTypes.bool,
  bold: PropTypes.bool,
  heavy: PropTypes.bool,
  black: PropTypes.bool,
  primaryColor: PropTypes.bool,
  darkPrimaryColor: PropTypes.bool,
  lightPrimaryColor: PropTypes.bool,
  accentColor: PropTypes.bool,
  grayColor: PropTypes.bool,
  ThirdButtonColor: PropTypes.bool,
  SecoundButtonColor: PropTypes.bool,
  redColor: PropTypes.bool,
  BlackTextColor: PropTypes.bool,
  garycardcolor: PropTypes.bool,
  skycolor: PropTypes.bool,
  dividerColor: PropTypes.bool,
  whiteColor: PropTypes.bool,
  bluetext: PropTypes.bool,
  fieldColor: PropTypes.bool,
  blackColor: PropTypes.bool,
  hometilesubtext: PropTypes.bool,
  hometilemenhead: PropTypes.bool,
  emaillinkcolor: PropTypes.bool,
  fontgreendark: PropTypes.bool,
  greenColor: PropTypes.bool,
  mainButtonColor: PropTypes.bool,
  numberOfLines: PropTypes.number,
  textAlign: PropTypes.string,
  capitalize: PropTypes.bool,
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
};

Index.defaultProps = {
  header: false,
  heading: false,
  title1: false,
  title2: false,
  title3: false,
  title4: false,
  headline: false,
  body1: false,
  body2: false,
  callout: false,
  subhead: false,
  footnote: false,
  caption1: false,
  caption2: false,
  overline: false,
  thin: false,
  ultraLight: false,
  light: false,
  regular: false,
  medium: false,
  semibold: false,
  bold: false,
  heavy: false,
  black: false,
  primaryColor: false,
  darkPrimaryColor: false,
  lightPrimaryColor: false,
  accentColor: false,
  grayColor: false,
  dividerColor: false,
  whiteColor: false,
  fieldColor: false,
  blackColor: false,
  hometilesubtext: false,
  hometilemenhead: false,
  darkgreen: false,
  lightpurplecolor: false,
  ThirdButtonColor: false,
  SecoundButtonColor: false,
  redColor: false,
  BlackTextColor: false,
  garycardcolor: false,
  skycolor: false,
  mainButtonColor: false,
  fontgreendark: false,
  greenColor: false,
  emaillinkcolor: false,
  bluetext: false,
  numberOfLines: 10,
  textAlign: "left",
  style: {},
  children: "",
  capitalize: false,
  uppercase: false,
  lowercase: false,
  lineheighthigh: false,
  lineheightmedium: false,
  lineheightreguler: false,
  lineheightxsmall: false,
};
