import React, { useEffect, useState } from 'react'
import '../CheckBox/checkbox.css'

export default function CheckBox(props) {
    const { value, name, error, formEdit, formvalue, setFormValue, lable, setcheckboxOption } = props

    // Create an initial state object based on elements' isChecked property
    const elements = value;
    console.log('elements', elements);

    const initialState = elements.reduce((acc, curr) => {
        acc[curr.Name] = curr.IsChecked == 1 ? true : false;
        return acc;
    }, {});

    const [checkedItems, setCheckedItems] = useState(initialState);
    console.log('checkedItems', checkedItems);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;


        setCheckedItems({
            ...checkedItems,
            [name]: checked
        });
    };

    const checkedElements = elements.filter((element) => checkedItems[element.Name]);

    useEffect(() => {
        const margearry = checkedElements.map((item) => {
            return ({ ...item, IsChecked: 1 })
        })
        console.log('margearry', margearry);
        setcheckboxOption(margearry)
        setFormValue({ ...formvalue, [name]: margearry })
    }, [checkedItems, value]);

    return (
        <div>
            <label className='checkbox-lable' htmlFor="checkbox-main">{lable}</label>
            <div className='checkbox-design'>
                {elements.map((element, index) => (
                    <div key={index} id='checkbox-main' className='checkbox-wrapper-4'>
                        <input
                            className='inp-cbx'
                            type="checkbox"
                            id={`checkbox${element.Name}`}
                            name={element.Name}
                            checked={checkedItems[element.Name]}
                            onChange={handleCheckboxChange}
                        />
                        <label className='check-lable cbx' htmlFor={`checkbox${element.Name}`}>
                            <span>
                                <svg width="12px" height="10px">
                                    <use href="#check-4"></use>
                                </svg></span><span>{element.Name}</span></label>
                        <svg className="inline-svg">
                            <symbol id="check-4" viewBox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </symbol>
                        </svg>
                    </div>
                ))}
            </div>
            {error && <div className='chackbox-error-line'>
                Please select at least one Checkbox
            </div>}
        </div>
    )
}