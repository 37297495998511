import { URLs } from "./URLs";
import { API, HTTPMethod } from "../API/API";

export function APIControllerError(code, message) {
    this.code = code;
    this.message = message;
}

export function APIControllerResponse(object, error) {
    this.object = object;
    this.error = error;
}

export function GetFacebookPages(access_token) {
    return new Promise((resolve, reject) => {
        API.shared.request1(HTTPMethod.get, "https://graph.facebook.com/v12.0/me/accounts?access_token=" + access_token)
            .then((response) => {
                if (response && response.StatusCode === 401) {
                    let error = new APIControllerError(
                        response.StatusCode,
                        response.Message
                    );
                    resolve(new APIControllerResponse(null, error));
                } else if (response && response.data) {
                    resolve(new APIControllerResponse(response, null));
                }
            }).catch((error) => {
                resolve(new APIControllerResponse(null, error));
            });
    });
}

export function GetInstaAccount(access_token, page_id) {
    return new Promise((resolve, reject) => {
        API.shared.request1(HTTPMethod.get, "https://graph.facebook.com/v13.0/" + page_id + "?fields=instagram_business_account&access_token=" + access_token)
            .then((response) => {
                if (response != null && response.instagram_business_account != null) {
                    GetInstaAccountName(access_token, response.instagram_business_account.id)
                        .then((response_inner) => {
                            resolve(new APIControllerResponse(response_inner, null));
                        });
                } else {
                    resolve(new APIControllerResponse(null, response));
                }
            }).catch((error) => {
                resolve(new APIControllerResponse(null, error));
            });
    });
}

export function GetInstaAccountName(access_token, page_id) {
    return new Promise((resolve, reject) => {
        API.shared.request1(HTTPMethod.get, "https://graph.facebook.com/v13.0/" + page_id + "?fields=username&access_token=" + access_token)
            .then((response) => {
                resolve(response);
            }).catch((error) => {
                resolve(new APIControllerResponse(null, error));
            });
    });
}

export function DownloadCustomizableAsset(objParameter) {
    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, URLs.base + "api/Action/DownloadCustomizableAsset", JSON.stringify(objParameter))
            .then((response) => {
                if (response && response.StatusCode === 401) {
                    let error = new APIControllerError(response.StatusCode, response.Message);
                    resolve(new APIControllerResponse(null, error));
                } else if (response.headers.get("content-disposition") != null && response.headers.get("content-disposition").toString().indexOf("filename")) {
                    response.blob().then((data) => {
                        DownloadFileClientside(response.headers.get("content-disposition").split(" filename=")[1].trim(), data);

                        let error = new APIControllerError(response.StatusCode, response.Message);
                        resolve(new APIControllerResponse(null, error));
                    });
                }
            }).catch((error) => {
                resolve(new APIControllerResponse(null, error));
            });
    });
}

export function LP_DownloadMasterTemplate(ID) {
    let params = JSON.stringify({ ID: ID });

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, URLs.base + "API/LP/DownloadMasterTemplate", params)
            .then((response) => {
                if (response && response.StatusCode === 401) {
                    let error = new APIControllerError(response.StatusCode, response.Message);
                    resolve(new APIControllerResponse(null, error));
                } else {
                    var FileName = response.headers.get("content-disposition").split("=").pop().replace('"', "").replace('"', "");
                    response.blob().then((data) => {
                        DownloadFileClientside(FileName, data);
                    });
                }
            }).catch((error) => {
                resolve(new APIControllerResponse(null, error));
            });
    });
}

export function DownloadCommunicationTemplate(ID) {
    let params = JSON.stringify({ ID: ID });

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/DownloadCommunicationTemplate", params)
            .then((response) => {
                if (response && response.StatusCode === 401) {
                    let error = new APIControllerError(response.StatusCode, response.Message);
                    resolve(new APIControllerResponse(null, error));
                } else {
                    var FileName = response.headers.get("content-disposition").split("=").pop().replace('"', "").replace('"', "");
                    response.blob().then((data) => {
                        DownloadFileClientside(FileName, data);
                    });
                }
            }).catch((error) => {
                resolve(new APIControllerResponse(null, error));
            });
    });
}
export function DownloadTemplate(ID) {
    let params = JSON.stringify({ ID: ID });

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, URLs.base + "API/LN/DownloadTemplate", params)
            .then((response) => {
                if (response && response.StatusCode === 401) {
                    let error = new APIControllerError(response.StatusCode, response.Message);
                    resolve(new APIControllerResponse(null, error));
                } else {
                    var FileName = response.headers.get("content-disposition").split("=").pop().replace('"', "").replace('"', "");
                    response.blob().then((data) => {
                        DownloadFileClientside(FileName, data);
                    });
                }
            }).catch((error) => {
                resolve(new APIControllerResponse(null, error));
            });
    });
}

export function DownloadFileClientside(Name, response) {
    const href = window.URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", Name.replaceAll('"', "")); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export function AddEditDocument(formData, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", URLs.AddEditDocument, true);
    xhr.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("Access_Token")}`);
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            let response = JSON.parse(xhr.responseText);
            if (xhr.status === 200) {
                callback(null, response);
            } else {
                callback(xhr.status, null); // Pass error status to callback
            }
        }
    };
    xhr.send(formData);
}

export const Login = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "/token", JSON.stringify(objParameter));

// export const GetMySubscriptions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetMySubscriptions', JSON.stringify(objParameter));
export const GetNav = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetNav", JSON.stringify(objParameter));
export const AdminNav = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetNav", JSON.stringify(objParameter));

export const GetRights = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetRights", JSON.stringify(objParameter));
export const GetRightForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetRightForDropdown", JSON.stringify(objParameter));
export const GetRightByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetRightByID", JSON.stringify(objParameter));
export const DeleteRight = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/DeleteRight", JSON.stringify(objParameter));
export const AddEditRight = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/AddEditRight", JSON.stringify(objParameter));

//Roles
export const GetRoles = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetRoles", JSON.stringify(objParameter));
export const GetRoleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetRoleForDropdown", JSON.stringify(objParameter));
export const GetRoleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetRoleByID", JSON.stringify(objParameter));
export const DeleteRole = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/DeleteRole", JSON.stringify(objParameter));
export const AddEditRole = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/AddEditRole", JSON.stringify(objParameter));

// Screen
export const GetScreens = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetScreens", JSON.stringify(objParameter));
export const GetScreenForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetScreenForDropdown", JSON.stringify(objParameter));
export const GetScreenByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetScreenByID", JSON.stringify(objParameter));
export const DeleteScreen = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/DeleteScreen", JSON.stringify(objParameter));
export const AddEditScreen = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/AddEditScreen", JSON.stringify(objParameter));
export const ScreenForDropdownIsParent = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/ACS/GetScreenForDropdown", JSON.stringify(objParameter));

//TenantsDomain
export const GetTenantDomains = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/GetTenantDomains", JSON.stringify(objParameter));
export const AddEditTenantDomain = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/AddEditTenantDomain", JSON.stringify(objParameter));
export const DeleteTenantDomain = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/DeleteTenantDomain", JSON.stringify(objParameter));
export const GetTenantDomainByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/GetTenantDomainByID", JSON.stringify(objParameter));
export const GetTenantDomainForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/GetTenantDomainForDropdown", JSON.stringify(objParameter));

// APILogs
export const GetAPILogs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Master/GetAPILogs", JSON.stringify(objParameter));

//TenantSMTP
export const GetTenantSMTPForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/GetTenantSMTPForDropdown", JSON.stringify(objParameter));
export const GetTenantSMTPByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/GetTenantSMTPByID", JSON.stringify(objParameter));
export const GetTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/GetTenantSMTP", JSON.stringify(objParameter));
export const DeleteTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/DeleteTenantSMTP", JSON.stringify(objParameter));
export const AddEditTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/AddEditTenantSMTP", JSON.stringify(objParameter));

//Tenants
export const GetTenants = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/GetTenants", JSON.stringify(objParameter));
export const AddEditTenant = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/AddEditTenant", JSON.stringify(objParameter));
export const DeleteTenant = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/DeleteTenant", JSON.stringify(objParameter));
export const GetTenantByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/GetTenantByID", JSON.stringify(objParameter));
export const GetTenantForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/GetTenantForDropdown", JSON.stringify(objParameter));

// TenantConfiguration
export const GetTenantConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Tenant/GetTenantConfiguration", JSON.stringify(objParameter));

// ChatGPTTransactions
export const GetChatGPTTransactions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Master/GetChatGPTTransactions", JSON.stringify(objParameter));

// Communication
export const GetCommunications = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Communication/GetCommunications", JSON.stringify(objParameter));
// export const DownloadCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/DownloadCommunicationTemplate', JSON.stringify(objParameter));
export const SetCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Communication/SetCommunicationTemplate", JSON.stringify(objParameter));
// export const DownloadCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/DownloadCommunicationTemplate', JSON.stringify(objParameter));

// CommunicationConfiguration
export const AddEditCommunicationConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/AddEditCommunicationConfiguration", JSON.stringify(objParameter));
export const DeleteCommunicationConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/DeleteCommunicationConfiguration", JSON.stringify(objParameter));
export const GetCommunicationConfigurationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/GetCommunicationConfigurationByID", JSON.stringify(objParameter));
export const GetCommunicationConfigurations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/GetCommunicationConfigurations", JSON.stringify(objParameter));
export const GetCommunicationConfigurationForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/GetCommunicationConfigurationForDropdown", JSON.stringify(objParameter));

//CommunicationTemplates
export const GetCommunicationTemplates = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/GetCommunicationTemplates", JSON.stringify(objParameter));
export const AddEditCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/AddEditCommunicationTemplate", JSON.stringify(objParameter));
export const DeleteCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/DeleteCommunicationTemplate", JSON.stringify(objParameter));
export const GetCommunicationTemplateByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/GetCommunicationTemplateByID", JSON.stringify(objParameter));
export const GetCommunicationTemplateForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Communication/GetCommunicationTemplateForDropdown", JSON.stringify(objParameter));

//Country
export const AddEditCountry = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditCountry", JSON.stringify(objParameter));
export const DeleteCountry = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteCountry", JSON.stringify(objParameter));
export const GetCountryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetCountryByID", JSON.stringify(objParameter));
export const GetCountries = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetCountries", JSON.stringify(objParameter));
export const GetCountryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetCountryForDropdown", JSON.stringify(objParameter));

//State
export const GetStates = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetStates", JSON.stringify(objParameter));
export const GetStateForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetStateForDropdown", JSON.stringify(objParameter));
export const GetStateByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetStateByID", JSON.stringify(objParameter));
export const DeleteState = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteState", JSON.stringify(objParameter));
export const AddEditState = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditState", JSON.stringify(objParameter));

//Areas
export const GetAreas = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetAreas", JSON.stringify(objParameter));
export const GetAreaForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetAreaForDropdown", JSON.stringify(objParameter));
export const GetAreaByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetAreaByID", JSON.stringify(objParameter));
export const DeleteArea = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteArea", JSON.stringify(objParameter));
export const AddEditArea = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditArea", JSON.stringify(objParameter));

//Categories
export const GetCategoryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetCategoryForDropdown", JSON.stringify(objParameter));
export const GetCategoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetCategoryByID", JSON.stringify(objParameter));
export const GetCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetCategories", JSON.stringify(objParameter));
export const DeleteCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteCategory", JSON.stringify(objParameter));
export const AddEditCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditCategory", JSON.stringify(objParameter));

//Cities
export const GetCityForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetCityForDropdown", JSON.stringify(objParameter));
export const GetCityByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetCityByID", JSON.stringify(objParameter));
export const GetCities = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetCities", JSON.stringify(objParameter));
export const DeleteCity = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteCity", JSON.stringify(objParameter));
export const AddEditCity = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditCity", JSON.stringify(objParameter));

//Departments
export const GetDepartments = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetDepartments", JSON.stringify(objParameter));
export const GetDepartmentForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetDepartmentForDropdown", JSON.stringify(objParameter));
export const GetDepartmentByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetDepartmentByID", JSON.stringify(objParameter));
export const DeleteDepartment = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteDepartment", JSON.stringify(objParameter));
export const AddEditDepartment = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditDepartment", JSON.stringify(objParameter));

//DocumentTypes
export const GetDocumentTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetDocumentTypes", JSON.stringify(objParameter));
export const GetDocumentTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetDocumentTypeForDropdown", JSON.stringify(objParameter));
export const GetDocumentTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetDocumentTypeByID", JSON.stringify(objParameter));
export const DeleteDocumentType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteDocumentType", JSON.stringify(objParameter));
export const AddEditDocumentType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditDocumentType", JSON.stringify(objParameter));

//Memberships
export const GetMemberships = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetMemberships", JSON.stringify(objParameter));
export const GetMembershipForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetMembershipForDropdown", JSON.stringify(objParameter));
export const GetMembershipByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetMembershipByID", JSON.stringify(objParameter));
export const DeleteMembership = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteMembership", JSON.stringify(objParameter));
export const AddEditMembership = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditMembership", JSON.stringify(objParameter));

//Positions
export const GetPositions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetPositions", JSON.stringify(objParameter));
export const GetPositionForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetPositionForDropdown", JSON.stringify(objParameter));
export const GetPositionByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetPositionByID", JSON.stringify(objParameter));
export const DeletePosition = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeletePosition", JSON.stringify(objParameter));
export const AddEditPosition = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditPosition", JSON.stringify(objParameter));

// Lp
export const GetLPLandingPages = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/LP/GetLPLandingPages", JSON.stringify(objParameter));

//PostageCharges
export const GetPostageCharges = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetPostageCharges", JSON.stringify(objParameter));
export const GetPostageChargeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetPostageChargeForDropdown", JSON.stringify(objParameter));
export const GetPostageChargeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetPostageChargeByID", JSON.stringify(objParameter));
export const DeletePostageCharge = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeletePostageCharge", JSON.stringify(objParameter));
export const AddEditPostageCharge = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditPostageCharge", JSON.stringify(objParameter));

// leads
export const GetLeads = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/LP/GetLeads", JSON.stringify(objParameter));

//Segments
export const GetSegments = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetSegments", JSON.stringify(objParameter));
export const GetSegmentForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetSegmentForDropdown", JSON.stringify(objParameter));
export const GetSegmentByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetSegmentByID", JSON.stringify(objParameter));
export const DeleteSegment = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteSegment", JSON.stringify(objParameter));
export const AddEditSegment = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditSegment", JSON.stringify(objParameter));

//UserTypes
export const GetUserTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetUserTypes", JSON.stringify(objParameter));
export const GetUserTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetUserTypeForDropdown", JSON.stringify(objParameter));
export const GetUserTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetUserTypeByID", JSON.stringify(objParameter));
export const DeleteUserType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteUserType", JSON.stringify(objParameter));
export const AddEditUserType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditUserType", JSON.stringify(objParameter));

//Regions
export const GetRegions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetRegions", JSON.stringify(objParameter));
export const GetRegionForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetRegionForDropdown", JSON.stringify(objParameter));
export const GetRegionByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetRegionByID", JSON.stringify(objParameter));
export const DeleteRegion = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteRegion", JSON.stringify(objParameter));
export const AddEditRegion = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditRegion", JSON.stringify(objParameter));

//Territory
export const GetTerritoryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetTerritoryForDropdown", JSON.stringify(objParameter));
export const GetTerritoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetTerritoryByID", JSON.stringify(objParameter));
export const GetTerritories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetTerritories", JSON.stringify(objParameter));
export const DeleteTerritory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteTerritory", JSON.stringify(objParameter));
export const AddEditTerritory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditTerritory", JSON.stringify(objParameter));

//Zipcodes
export const GetZipcodes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetZipcodes", JSON.stringify(objParameter));
export const GetZipcodeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetZipcodeForDropdown", JSON.stringify(objParameter));
export const GetZipcodeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetZipcodeByID", JSON.stringify(objParameter));
export const DeleteZipcode = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteZipcode", JSON.stringify(objParameter));
export const AddEditZipcode = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditZipcode", JSON.stringify(objParameter));

// PayPalSubscription
export const GetPayPalSubscriptionPlanForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetPayPalSubscriptionPlanForDropdown", JSON.stringify(objParameter));
export const GetPayPalSubscriptionPlans = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetPayPalSubscriptionPlans", JSON.stringify(objParameter));
export const GetPayPalSubscriptionPlanByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetPayPalSubscriptionPlanByID", JSON.stringify(objParameter));
export const DeletePayPalSubscriptionPlan = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeletePayPalSubscriptionPlan", JSON.stringify(objParameter));
export const AddEditPayPalSubscriptionPlan = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/AddEditPayPalSubscriptionPlan", JSON.stringify(objParameter));

//GlobalModules
export const GetGlobalModules = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalModules", JSON.stringify(objParameter));
export const GetGlobalModuleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalModuleForDropdown", JSON.stringify(objParameter));
export const GetGlobalModuleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalModuleByID", JSON.stringify(objParameter));
export const DeleteGlobalModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/DeleteGlobalModule", JSON.stringify(objParameter));
export const AddEditGlobalModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/AddEditGlobalModule", JSON.stringify(objParameter));

// GlobalActions
export const GetGlobalActions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalActions', JSON.stringify(objParameter));
export const GetGlobalActionForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalActionForDropdown', JSON.stringify(objParameter));
export const GetGlobalActionByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalActionByID', JSON.stringify(objParameter));
export const DeleteGlobalAction = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/DeleteGlobalAction', JSON.stringify(objParameter));
export const AddEditGlobalAction = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/AddEditGlobalAction', JSON.stringify(objParameter));

//GlobalSubModules
export const GetGlobalSubModules = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalSubModules", JSON.stringify(objParameter));
export const GetGlobalSubModuleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalSubModuleForDropdown", JSON.stringify(objParameter));
export const GetGlobalSubModuleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalSubModuleByID", JSON.stringify(objParameter));
export const DeleteGlobalSubModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/DeleteGlobalSubModule", JSON.stringify(objParameter));
export const AddEditGlobalSubModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/AddEditGlobalSubModule", JSON.stringify(objParameter));

//GlobalAssetTypes
export const GetGlobalAssetTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalAssetTypes", JSON.stringify(objParameter));
export const GetGlobalAssetTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalAssetTypeForDropdown", JSON.stringify(objParameter));
export const GetGlobalAssetTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalAssetTypeByID", JSON.stringify(objParameter));
export const DeleteGlobalAssetType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/DeleteGlobalAssetType", JSON.stringify(objParameter));
export const AddEditGlobalAssetType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/AddEditGlobalAssetType", JSON.stringify(objParameter));

//GlobalAssetSizes
export const GetGlobalAssetSizes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalAssetSizes", JSON.stringify(objParameter));
export const GetGlobalAssetSizeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalAssetSizeForDropdown", JSON.stringify(objParameter));
export const GetGlobalAssetSizeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalAssetSizeByID", JSON.stringify(objParameter));
export const DeleteGlobalAssetSize = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/DeleteGlobalAssetSize", JSON.stringify(objParameter));
export const AddEditGlobalAssetSize = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/AddEditGlobalAssetSize", JSON.stringify(objParameter));

//Modules
export const GetModules = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetModules", JSON.stringify(objParameter));
export const GetModuleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetModuleForDropdown", JSON.stringify(objParameter));
export const GetModuleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetModuleByID", JSON.stringify(objParameter));
export const DeleteModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/DeleteModule", JSON.stringify(objParameter));
export const AddEditModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/AddEditModule", JSON.stringify(objParameter));

//SubModules
export const GetSubModules = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetSubModules", JSON.stringify(objParameter));
export const GetSubModuleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetSubModuleForDropdown", JSON.stringify(objParameter));
export const GetSubModuleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetSubModuleByID", JSON.stringify(objParameter));
export const DeleteSubModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/DeleteSubModule", JSON.stringify(objParameter));
export const AddEditSubModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/AddEditSubModule", JSON.stringify(objParameter));

//AssetTypes
export const GetAssetTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetAssetTypes", JSON.stringify(objParameter));
export const GetAssetTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetAssetTypeForDropdown", JSON.stringify(objParameter));
export const GetAssetTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetAssetTypeByID", JSON.stringify(objParameter));
export const DeleteAssetType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/DeleteAssetType", JSON.stringify(objParameter));
export const AddEditAssetType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/AddEditAssetType", JSON.stringify(objParameter));

//AssetSizes
export const GetAssetSizes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetAssetSizes", JSON.stringify(objParameter));
export const GetAssetSizeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetAssetSizeForDropdown", JSON.stringify(objParameter));
export const GetAssetSizeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetAssetSizeByID", JSON.stringify(objParameter));
export const DeleteAssetSize = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/DeleteAssetSize", JSON.stringify(objParameter));
export const AddEditAssetSize = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/AddEditAssetSize", JSON.stringify(objParameter));

//Product
export const GetProducts = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProducts", JSON.stringify(objParameter));
export const GetProductForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductForDropdown", JSON.stringify(objParameter));
export const GetProductByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductByID", JSON.stringify(objParameter));
export const DeleteProduct = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/DeleteProduct", JSON.stringify(objParameter));
export const AddEditProduct = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/AddEditProduct", JSON.stringify(objParameter));
export const GetProductTabs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Asset/GetProductTabs", JSON.stringify(objParameter));

//ProductCategories
export const GetProductCategoryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductCategoryForDropdown", JSON.stringify(objParameter));
export const GetProductCategoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductCategoryByID", JSON.stringify(objParameter));
export const GetProductCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductCategories", JSON.stringify(objParameter));
export const DeleteProductCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/DeleteProductCategory", JSON.stringify(objParameter));
export const AddEditProductCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/AddEditProductCategory", JSON.stringify(objParameter));

// ProductActions
export const GetProductActions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductActions', JSON.stringify(objParameter));
export const GetProductActionForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductActionForDropdown', JSON.stringify(objParameter));
export const GetProductActionByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductActionByID', JSON.stringify(objParameter));
export const DeleteProductAction = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/DeleteProductAction', JSON.stringify(objParameter));
export const AddEditProductAction = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/AddEditProductAction', JSON.stringify(objParameter));

//ProductCategorySubCategories
export const GetProductCategorySubCategoryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductCategorySubCategoryForDropdown", JSON.stringify(objParameter));
export const GetProductCategorySubCategoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductCategorySubCategoryByID", JSON.stringify(objParameter));
export const GetProductCategorySubCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductCategorySubCategories", JSON.stringify(objParameter));
export const DeleteProductCategorySubCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/DeleteProductCategorySubCategory", JSON.stringify(objParameter));
export const AddEditProductCategorySubCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/AddEditProductCategorySubCategory", JSON.stringify(objParameter));

//globalfilters
export const GetGlobalFilters = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalFilters", JSON.stringify(objParameter));
export const GetGlobalFilterForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalFilterForDropdown", JSON.stringify(objParameter));
export const GetGlobalFilterByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalFilterByID", JSON.stringify(objParameter));
export const DeleteGlobalFilter = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/DeleteGlobalFilter", JSON.stringify(objParameter));
export const AddEditGlobalFilter = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/AddEditGlobalFilter", JSON.stringify(objParameter));

//Filter
export const GetProductFilterForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductFilterForDropdown", JSON.stringify(objParameter));
export const GetFilters = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetFilters", JSON.stringify(objParameter));
export const GetFilterForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetFilterForDropdown", JSON.stringify(objParameter));
export const GetFilterByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/GetFilterByID", JSON.stringify(objParameter));
export const DeleteFilter = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/DeleteFilter", JSON.stringify(objParameter));
export const AddEditFilter = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Local/AddEditFilter", JSON.stringify(objParameter));

//ProductFiltersDetails
export const GetProductFilterDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductFilterDetails", JSON.stringify(objParameter));
export const GetProductFilterDetailForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductFilterDetailForDropdown", JSON.stringify(objParameter));
export const GetProductFilterDetailByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductFilterDetailByID", JSON.stringify(objParameter));
export const DeleteProductFilterDetail = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/DeleteProductFilterDetail", JSON.stringify(objParameter));
export const AddEditProductFilterDetail = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/AddEditProductFilterDetail", JSON.stringify(objParameter));

//BL_ST_LensTypes
export const GetBL_ST_LensTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/GetBL_ST_LensTypes", JSON.stringify(objParameter));
export const GetBL_ST_LensTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/GetBL_ST_LensTypeForDropdown", JSON.stringify(objParameter));
export const GetBL_ST_LensTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/GetBL_ST_LensTypeByID", JSON.stringify(objParameter));
export const DeleteBL_ST_LensType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/DeleteBL_ST_LensType", JSON.stringify(objParameter));
export const AddEditBL_ST_LensType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/AddEditBL_ST_LensType", JSON.stringify(objParameter));

//BL_ST_LocationPricing
export const GetBL_ST_LocationPricingForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/GetBL_ST_LocationPricingForDropdown", JSON.stringify(objParameter));
export const GetBL_ST_LocationPricingByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/GetBL_ST_LocationPricingByID", JSON.stringify(objParameter));
export const GetBL_ST_LocationPricing = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/GetBL_ST_LocationPricing", JSON.stringify(objParameter));
export const DeleteBL_ST_LocationPricing = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/DeleteBL_ST_LocationPricing", JSON.stringify(objParameter));
export const AddEditBL_ST_LocationPricing = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/AddEditBL_ST_LocationPricing", JSON.stringify(objParameter));

//BL_ST_Configurations
export const GetBL_ST_Configurations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/GetBL_ST_Configurations", JSON.stringify(objParameter));
export const GetBL_ST_ConfigurationForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/GetBL_ST_ConfigurationForDropdown", JSON.stringify(objParameter));
export const GetBL_ST_ConfigurationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/GetBL_ST_ConfigurationByID", JSON.stringify(objParameter));
export const DeleteBL_ST_Configuration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/DeleteBL_ST_Configuration", JSON.stringify(objParameter));
export const AddEditBL_ST_Configuration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/AddEditBL_ST_Configuration", JSON.stringify(objParameter));

//BL_ST_LocationPricingHistory
export const GetBL_ST_LocationPricingHistory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BL_ST/GetBL_ST_LocationPricingHistory", JSON.stringify(objParameter));

//ProductAssets
export const GetProductAssetByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/GetProductAssetByID", JSON.stringify(objParameter));
export const AddEditProductAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Asset/AddEditProductAsset", JSON.stringify(objParameter));
export const DeleteProductAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Asset/DeleteProductAsset", JSON.stringify(objParameter));

//ProductAssetsForUploading
export const GetProductAssetsForUploading = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Asset/GetProductAssetsForUploading", JSON.stringify(objParameter));

//UserTerritories
export const GetUserTerritoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetUserTerritoryByID", JSON.stringify(objParameter));
export const GetUserTerritories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetUserTerritories", JSON.stringify(objParameter));
export const DeleteUserTerritory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/DeleteUserTerritory", JSON.stringify(objParameter));
export const AddEditUserTerritory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/AddEditUserTerritory", JSON.stringify(objParameter));

// ProductassetGroup
export const GetProductAssetGroups = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetProductAssetGroups', JSON.stringify(objParameter));
export const GetProductAssetGroupForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetProductAssetGroupForDropdown', JSON.stringify(objParameter));
export const GetProductAssetGroupByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetProductAssetGroupByID', JSON.stringify(objParameter));
export const DeleteProductAssetGroup = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteProductAssetGroup', JSON.stringify(objParameter));
export const AddEditProductAssetGroup = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditProductAssetGroup', JSON.stringify(objParameter));

// Location Product Asset Groups
export const GetLocationProductAssetGroups = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetLocationProductAssetGroups', JSON.stringify(objParameter));
export const GetLocationProductAssetGroupByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetLocationProductAssetGroupByID', JSON.stringify(objParameter));
export const DeleteLocationProductAssetGroup = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/DeleteLocationProductAssetGroup', JSON.stringify(objParameter));
export const AddEditLocationProductAssetGroup = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/AddEditLocationProductAssetGroup', JSON.stringify(objParameter));

//Locations
export const GetLocations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetLocations", JSON.stringify(objParameter));
export const GetLocationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetLocationByID", JSON.stringify(objParameter));
export const DeleteLocation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/DeleteLocation", JSON.stringify(objParameter));
export const AddEditLocation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/AddEditLocation", JSON.stringify(objParameter));
export const GetLocationSummary = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetLocationSummary", JSON.stringify(objParameter));
export const Location_GetRadius = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/Location_GetRadius", JSON.stringify(objParameter));
export const Location_SettRadius = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/Location_SettRadius", JSON.stringify(objParameter));

// location master
export const GetLocationMaster = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetLocationMaster", JSON.stringify(objParameter));
export const DeleteLocationMaster = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/DeleteLocationMaster", JSON.stringify(objParameter));

//LocationMemberships
export const GetLocationMemberships = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetLocationMemberships", JSON.stringify(objParameter));
export const GetLocationMembershipByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetLocationMembershipByID", JSON.stringify(objParameter));
export const DeleteLocationMembership = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/DeleteLocationMembership", JSON.stringify(objParameter));
export const AddEditLocationMembership = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/AddEditLocationMembership", JSON.stringify(objParameter));

//Users
export const GetUsers = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetUsers", JSON.stringify(objParameter));
export const GetUserByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetUserByID", JSON.stringify(objParameter));
export const DeleteUser = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/DeleteUser", JSON.stringify(objParameter));
export const AddEditUser = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/AddEditUser", JSON.stringify(objParameter));

//LPTemplates
export const GetLPTemplates = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/GetLPTemplates", JSON.stringify(objParameter));
export const GetLPTemplateByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/GetLPTemplateByID", JSON.stringify(objParameter));
export const DeleteLPTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/DeleteLPTemplate", JSON.stringify(objParameter));
export const AddEditLPTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/AddEditLPTemplate", JSON.stringify(objParameter));
export const GenerateLandingPages = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/GenerateLandingPages", JSON.stringify(objParameter));

//LPReports
export const GetLPReports = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/GetLPReports", JSON.stringify(objParameter));
export const DeleteLPReport = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/DeleteLPReport", JSON.stringify(objParameter));
export const AddEditLPReport = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/AddEditLPReport", JSON.stringify(objParameter));
export const ImportAnalytics = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/ImportAnalytics", JSON.stringify(objParameter));

//LPReportDetails
export const GetLPReportDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/GetLPReportDetails", JSON.stringify(objParameter));

//LPHistory
export const GetLPHistory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/LP/GetLPHistory", JSON.stringify(objParameter));

//GetBugTracker
export const GetBugTracker = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BugTracker/GetBugTracker", JSON.stringify(objParameter));
export const ResolveBug = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BugTracker/ResolveBug", JSON.stringify(objParameter));
export const GetBuyByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/BugTracker/GetBuyByID", JSON.stringify(objParameter));

// Supply tools
export const GetBL_ST_LocationDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/BL_ST/GetBL_ST_LocationDetails", JSON.stringify(objParameter));
export const GetSupplyCostCalculatorLead = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/BL_ST/GetSupplyCostCalculatorLead", JSON.stringify(objParameter));
export const AddEditBL_ST_LocationDetail = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/BL_ST/AddEditBL_ST_LocationDetail", JSON.stringify(objParameter));
export const GetBL_ST_LocationDetailByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/BL_ST/GetBL_ST_LocationDetailByID", JSON.stringify(objParameter));
export const GetBL_ST_AccessHistory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/BL_ST/GetBL_ST_AccessHistory", JSON.stringify(objParameter));

//Documents
export const GetDocuments = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetDocuments", JSON.stringify(objParameter));
export const GetDocumentByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetDocumentByID", JSON.stringify(objParameter));
export const DeleteDocument = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/DeleteDocument", JSON.stringify(objParameter));
export const GetDocumentForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Master/GetDocumentForDropdown", JSON.stringify(objParameter));

// subscripition
export const GetSubscriptions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Calendar/GetSubscriptions", JSON.stringify(objParameter));
export const GetScheduledPosts = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Calendar/GetScheduledPosts", JSON.stringify(objParameter));
export const GetSubscriptionSchedulerHistory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Calendar/GetSubscriptionSchedulerHistory", JSON.stringify(objParameter));
export const GetSubscriptionStatusHistory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Calendar/GetSubscriptionStatusHistory', JSON.stringify(objParameter));

// EmailLogs
export const GetSubscriptionEmailLogs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/GetSubscriptionEmailLogs", JSON.stringify(objParameter));

// SN styles
export const GetSNStyles = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/GetSNStyles", JSON.stringify(objParameter));
export const GetSNStyleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/GetSNStyleByID", JSON.stringify(objParameter));
export const DeleteSNStyle = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/DeleteSNStyle", JSON.stringify(objParameter));
export const AddEditSNStyle = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/AddEditSNStyle", JSON.stringify(objParameter));
export const GetSNStyleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/GetSNStyleForDropdown", JSON.stringify(objParameter));

// SN GiftCards
export const GetSNGiftCards = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/GetSNGiftCards", JSON.stringify(objParameter));
export const GetSNGiftCardByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/GetSNGiftCardByID", JSON.stringify(objParameter));
export const DeleteSNGiftCard = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/DeleteSNGiftCard", JSON.stringify(objParameter));
export const AddEditSNGiftCard = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/AddEditSNGiftCard", JSON.stringify(objParameter));
export const GetSNGiftCardForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/GetSNGiftCardForDropdown", JSON.stringify(objParameter));

// SN Addressbook
export const GetSNAddressBook = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/GetSNAddressBook", JSON.stringify(objParameter));
export const GetSNAddressBookByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/GetSNAddressBookByID", JSON.stringify(objParameter));
export const DeleteSNAddressBook = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/DeleteSNAddressBook", JSON.stringify(objParameter));
export const AddEditSNAddressBook = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/AddEditSNAddressBook", JSON.stringify(objParameter));

// SN Templates
export const GetSNTemplates = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/GetSNTemplates", JSON.stringify(objParameter));
export const GetSNTemplateByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/GetSNTemplateByID", JSON.stringify(objParameter));
export const DeleteSNTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/DeleteSNTemplate", JSON.stringify(objParameter));
export const AddEditSNTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/SN/AddEditSNTemplate", JSON.stringify(objParameter));

// SN Order
export const SyncOrders = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "/api/SN/SyncOrders", JSON.stringify(objParameter));

// LN Templates
export const GetLNTemplates = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/GetLNTemplates', JSON.stringify(objParameter));
export const GetLNTemplateForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/GetLNTemplateForDropdown', JSON.stringify(objParameter));
export const GetLNTemplateByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/GetLNTemplateByID', JSON.stringify(objParameter));
export const DeleteLNTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/DeleteLNTemplate', JSON.stringify(objParameter));
export const AddEditLNTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/AddEditLNTemplate', JSON.stringify(objParameter));

// LN FilterLocationsForLNCampaign
export const FilterLocationsForLNCampaign = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/LN/FilterLocationsForLNCampaign', JSON.stringify(objParameter));
export const DeleteLocationFromLNCampaign = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/DeleteLocationFromLNCampaign', JSON.stringify(objParameter));
export const AddLocationToLNCampaign = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/AddLocationToLNCampaign', JSON.stringify(objParameter));

// LN Campaign
export const GetLNCampaigns = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/GetLNCampaigns', JSON.stringify(objParameter));
export const GetLNCampaignByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/GetLNCampaignByID', JSON.stringify(objParameter));
export const DeleteLNCampaign = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/DeleteLNCampaign', JSON.stringify(objParameter));
export const AddEditLNCampaign = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/AddEditLNCampaign', JSON.stringify(objParameter));
export const StartStopCampaign = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + '/API/LN/StartStopCampaign', JSON.stringify(objParameter));
export const CancelCampaign = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/CancelCampaign', JSON.stringify(objParameter));
export const SyncMailWizz = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/LN/SyncMailWizz', JSON.stringify(objParameter));

// LN Campaign History
export const GetLNCampaignHistory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LN/GetLNCampaignHistory', JSON.stringify(objParameter));

// LN Campaign Details
export const GetLNCampaignDetailsByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/LN/GetLNCampaignDetailsByID', JSON.stringify(objParameter));

// Frontend Side APIs
export const GetHome = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetHome", JSON.stringify(objParameter));
export const GetHomeDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetHomeDetails", JSON.stringify(objParameter));
export const GetHomeSubCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetHomeSubCategories", JSON.stringify(objParameter));
export const GetActionLocations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetLocations", JSON.stringify(objParameter));
export const GetActionLocationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetLocationByID", JSON.stringify(objParameter));
export const GetAdBuilderAssets = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetAdBuilderAssets", JSON.stringify(objParameter));
export const UpdateLocation_Featured_Location = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/UpdateFeaturedLocation", JSON.stringify(objParameter));
export const UpdateLocation_Location = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/UpdateLocation_Location", JSON.stringify(objParameter));
export const UpdateLocation_SocialAutomation_SelectAll = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/UpdateLocation_SocialAutomation_SelectAll", JSON.stringify(objParameter));
export const GetCategorySubCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetCategorySubCategories", JSON.stringify(objParameter));
export const AddEditMyCustomPost = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/AddEditMyCustomPost", JSON.stringify(objParameter));
export const RescheduleMyPost = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/RescheduleMyPost", JSON.stringify(objParameter));
export const DeletePost = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/DeletePost", JSON.stringify(objParameter));
export const AddEditCart = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/AddEditCart", JSON.stringify(objParameter));
export const AddEditOrder = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/AddEditOrder", JSON.stringify(objParameter));
export const UpdateLocation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/UpdateLocation", JSON.stringify(objParameter));

// promowebsite APIs
export const GetMyPromoWebsite = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetMyPromoWebsite", JSON.stringify(objParameter));

// Cart
export const GetCart = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetCart", JSON.stringify(objParameter));
export const DeleteCart = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/DeleteCart", JSON.stringify(objParameter));
export const GetArtwork = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetArtwork", JSON.stringify(objParameter));

// CalculatePostageCharges
export const CalculatePostageCharges = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/CalculatePostageCharges", JSON.stringify(objParameter));

// Simpaly Noted
export const GetAdBuilderAssets_SN = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetAdBuilderAssets_SN", JSON.stringify(objParameter));

// Calendar
export const GetMyCalendar = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetMyCalendar", JSON.stringify(objParameter));
export const GeneratePostCopy = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GeneratePostCopy", JSON.stringify(objParameter));
export const AddEditSubscription = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/AddEditSubscription", JSON.stringify(objParameter));
export const SubscribeNowWithoutPaypal = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/SubscribeNowWithoutPaypal", JSON.stringify(objParameter));

// Admin Calendar
export const GetCalendar = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/GetCalendar", JSON.stringify(objParameter));
export const AddEditCalendar = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/AddEditCalendar", JSON.stringify(objParameter));
export const DeleteCalendar = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/DeleteCalendar", JSON.stringify(objParameter));
export const GetCalendarByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/GetCalendarByID", JSON.stringify(objParameter));

// Orders Reports IDs
export const GetOrderReportIDs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Order/GetOrderReportIDs", JSON.stringify(objParameter));
export const GetOrderReportIDByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Order/GetOrderReportIDByID", JSON.stringify(objParameter));
export const DeleteOrderReportID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Order/DeleteOrderReportID", JSON.stringify(objParameter));
export const AddEditOrderReportID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Order/AddEditOrderReportID", JSON.stringify(objParameter));

// Order Reports
export const GetOrderReports = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Order/GetOrderReports", JSON.stringify(objParameter));
export const DeleteOrderReport = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Order/DeleteOrderReport", JSON.stringify(objParameter));

// Order
export const GetOrders = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Order/GetOrders", JSON.stringify(objParameter));
export const GetOrderForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Order/GetOrderForDropdown", JSON.stringify(objParameter));

// Calendar asset
export const GetCalendarAssets = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/GetCalendarAssets", JSON.stringify(objParameter));
export const GetCalendarAssetByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/GetCalendarAssetByID", JSON.stringify(objParameter));
export const DeleteCalendarAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/DeleteCalendarAsset", JSON.stringify(objParameter));
export const AddEditCalendarAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/AddEditCalendarAsset", JSON.stringify(objParameter));

//Download File
export const GetDownloadFile = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/DownloadFile", JSON.stringify(objParameter));
export const GetProductAssetsForSocialAutomation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetProductAssetsForSocialAutomation", JSON.stringify(objParameter));

//Editprofile
export const EditProfile = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/EditProfile", JSON.stringify(objParameter));

//GetMyProfile
export const GetMyProfile = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetMyProfile", JSON.stringify(objParameter));

// ProductFreeAsset
export const GetProductFreeAssetByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Asset/GetProductFreeAssetByID", JSON.stringify(objParameter));
// Asssets
export const DeleteProductFreeAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Asset/DeleteProductFreeAsset", JSON.stringify(objParameter));
export const AddEditProductFreeAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Asset/AddEditProductFreeAsset", JSON.stringify(objParameter));
export const GetProductFreeAsses = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Asset/GetProductFreeAsses", JSON.stringify(objParameter));

//GlobalModuleTabs
export const GetGlobalModuleTabs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalModuleTabs", JSON.stringify(objParameter));
export const GetGlobalModuleTabForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalModuleTabForDropdown", JSON.stringify(objParameter));
export const GetGlobalModuleTabByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/GetGlobalModuleTabByID", JSON.stringify(objParameter));
export const DeleteGlobalModuleTab = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/DeleteGlobalModuleTab", JSON.stringify(objParameter));
export const AddEditGlobalModuleTab = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Global/AddEditGlobalModuleTab", JSON.stringify(objParameter));

// Media Library
export const GetDocumentsAsLibrary = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Master/GetDocumentsAsLibrary", JSON.stringify(objParameter));
export const UpdateDocumentDescription = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Master/UpdateDocumentDescription", JSON.stringify(objParameter));
export const SetDocumentAsFavourite = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Master/SetDocumentAsFavourite", JSON.stringify(objParameter));

// Tenant
export const AddEditTenantConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Tenant/AddEditTenantConfiguration", JSON.stringify(objParameter));
export const GetTenantConfigurationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Tenant/GetTenantConfigurationByID", JSON.stringify(objParameter));

// my notification
export const GetMyNotifications = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetMyNotifications", JSON.stringify(objParameter));
export const MarkCommunicationAsRead = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/MarkCommunicationAsRead", JSON.stringify(objParameter));

// CustomPostFeed
export const GetCustomPostAdminFeed = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Calendar/GetCustomPostAdminFeed", JSON.stringify(objParameter));
export const FlagCustomPost = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Calendar/FlagCustomPost", JSON.stringify(objParameter));

//KeyWordChecked
export const GetCalendarNegativeKeywords = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/GetCalendarNegativeKeywords", JSON.stringify(objParameter));
export const GetCalendarNegativeKeywordByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/GetCalendarNegativeKeywordByID", JSON.stringify(objParameter));
export const DeleteCalendarNegativeKeyword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/DeleteCalendarNegativeKeyword", JSON.stringify(objParameter));
export const AddEditCalendarNegativeKeyword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Calendar/AddEditCalendarNegativeKeyword", JSON.stringify(objParameter));

// Cost Estimates Created
export const GetSupplyToolDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetSupplyToolDetails", JSON.stringify(objParameter));

// Subscriptions Details
export const GetMySubscriptions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetMySubscriptions", JSON.stringify(objParameter));
export const DeleteSubscription = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/DeleteSubscription", JSON.stringify(objParameter));
export const GetScreenConfigurations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetScreenConfigurations", JSON.stringify(objParameter));

//Registraion
export const VerifyLocation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/VerifyLocation", JSON.stringify(objParameter));
export const RegisterLocation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/RegisterLocation", JSON.stringify(objParameter));

//AddEditSupplyCostCalculatorLead
export const AddEditSupplyCostCalculatorLead = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/BL_ST/AddEditSupplyCostCalculatorLead", JSON.stringify(objParameter));
export const SetSupplyToolPracticePricing = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/SetSupplyToolPracticePricing", JSON.stringify(objParameter));

// Dashboard
export const GetMyPaidDashboard = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetMyPaidDashboard", JSON.stringify(objParameter));
export const GetOrganicDashboard = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetMyLPDashboard", JSON.stringify(objParameter));
export const GetOrderListForDashboard = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/Action/GetOrderListForDashboard", JSON.stringify(objParameter));

// My Order
export const GetMyOrders = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetOrders", JSON.stringify(objParameter));
export const GetOrderDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetOrderDetails", JSON.stringify(objParameter));

// ForgotPassword
export const ForgotPassword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/User/ForgotPassword", JSON.stringify(objParameter));
// Change password
export const ChangePassword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/User/ChangePassword", JSON.stringify(objParameter));
export const ChangeUserPassword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/User/ChangeUserPassword", JSON.stringify(objParameter));
// SetPassword
export const SetPassword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/User/SetPassword", JSON.stringify(objParameter));
// SetPixelTrackingCode
export const SetPixelTrackingCode = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/SetPixelTrackingCode", JSON.stringify(objParameter));
// Contact US
export const GetMyProfiles = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/User/GetMyProfile", JSON.stringify(objParameter));
// ApproveReject Location
export const ApproveRejectLocation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/User/ApproveRejectLocation", JSON.stringify(objParameter));

// Login logs
export const GetUserLoginLogs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Report/GetUserLoginLogs", JSON.stringify(objParameter));

// Impersonations
export const GetImpersonations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetImpersonations", JSON.stringify(objParameter));
export const GetImpersonationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetImpersonationByID", JSON.stringify(objParameter));
export const DeleteImpersonation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/DeleteImpersonation", JSON.stringify(objParameter));
export const AddEditImpersonation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/AddEditImpersonation", JSON.stringify(objParameter));

// ImpersonationLoginLogs
export const GetImpersonationLoginLogs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "API/User/GetImpersonationLoginLogs", JSON.stringify(objParameter));

// SearchLocations
export const SearchLocations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/User/SearchLocations", JSON.stringify(objParameter));

// Get My Groups
export const GetMyGroups = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetMyGroups", JSON.stringify(objParameter));

// ImpersonateMe
export const ImpersonateMe = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/User/ImpersonateMe", JSON.stringify(objParameter));

// DepersonateMe
export const DepersonateMe = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "/api/User/DepersonateMe", JSON.stringify(objParameter));

// GetMyGroupDetails
export const GetMyGroupDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "/api/Action/GetMyGroupDetails", JSON.stringify(objParameter));

// UpdateVeevaCode
export const UpdateVeevaCode = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Asset/UpdateVeevaCode", JSON.stringify(objParameter));

// FreeAssetsReport
export const GetFreeAssetsReport = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Report/GetFreeAssetsReport", JSON.stringify(objParameter));
export const AssetDownloadReport = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Report/AssetDownloadReport", JSON.stringify(objParameter));
export const RegistrationReport = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Report/RegistrationReport", JSON.stringify(objParameter));
export const AssetSummaryReport = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Report/AssetSummaryReport", JSON.stringify(objParameter));
export const BulkImportAddressBook_SN = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/BulkImportAddressBook_SN", JSON.stringify(objParameter));

export const ExchangeGoogleAuthorizationCode = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/ExchangeGoogleAuthorizationCode", JSON.stringify(objParameter));
export const GetGoogleAccessToken = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/GetGoogleAccessToken", JSON.stringify(objParameter));
export const SignoutFromGoogle = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/SignoutFromGoogle", JSON.stringify(objParameter));

export const InitiateTransaction = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/InitiateTransaction", JSON.stringify(objParameter));
export const OrderPayment = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + "api/Action/OrderPayment", JSON.stringify(objParameter));
export const FrontEnd = (details) => API.shared.request(HTTPMethod.post, URLs.base + "api/PayPal/FrontEnd", JSON.stringify({ details }));

// MarketingCategories
export const GetMarketingCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/GetMarketingCategories', JSON.stringify(objParameter));
export const GetMarketingCategoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/GetMarketingCategoryByID', JSON.stringify(objParameter));
export const GetMarketingCategoryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/GetMarketingCategoryForDropdown', JSON.stringify(objParameter));
export const GetMarketingCategoryLinkedProducts = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Marketing/GetMarketingCategoryLinkedProducts', JSON.stringify(objParameter));
export const DeleteMarketingCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/DeleteMarketingCategory', JSON.stringify(objParameter));
export const AddEditMarketingCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/AddEditMarketingCategory', JSON.stringify(objParameter));

// Marketing
export const GetMarketing = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/GetMarketing', JSON.stringify(objParameter));
export const GetMarketingByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/GetMarketingByID', JSON.stringify(objParameter));
export const DeleteMarketing = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/DeleteMarketing', JSON.stringify(objParameter));
export const AddEditMarketing = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/AddEditMarketing', JSON.stringify(objParameter));
export const GetMonthlyDashboard = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/GetMonthlyDashboard', JSON.stringify(objParameter));
export const GetWeekDashboard = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/GetWeeklyDashboard', JSON.stringify(objParameter));
// Marketing log
export const GetMarketingLogs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Marketing/GetMarketingLogs', JSON.stringify(objParameter));

export const AddEditMarketingFront = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/AddEditMarketing', JSON.stringify(objParameter));
export const DeleteMarketingFront = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/DeleteMarketing', JSON.stringify(objParameter));

// Market place
// color
export const GetMPColors = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/GetMPColors', JSON.stringify(objParameter));
export const GetMPColorForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/GetMPColorForDropdown', JSON.stringify(objParameter));
export const GetMPColorByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/GetMPColorByID', JSON.stringify(objParameter));
export const DeleteMPColor = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/DeleteMPColor', JSON.stringify(objParameter));
export const AddEditMPColor = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/AddEditMPColor', JSON.stringify(objParameter));

// size
export const GetMPSizes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/GetMPSizes', JSON.stringify(objParameter));
export const GetMPSizeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/GetMPSizeForDropdown', JSON.stringify(objParameter));
export const DeleteMPSize = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/DeleteMPSize', JSON.stringify(objParameter));
export const AddEditMPSize = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/AddEditMPSize', JSON.stringify(objParameter));
export const GetMPSizeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/GetMPSizeByID', JSON.stringify(objParameter));

// MPProduct
export const GetMPProducts = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/GetMPProducts', JSON.stringify(objParameter));
export const GetMPProductByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/GetMPProductByID', JSON.stringify(objParameter));
export const DeleteMPProduct = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/DeleteMPProduct', JSON.stringify(objParameter));
export const AddEditMPProduct = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/MarketPlace/AddEditMPProduct', JSON.stringify(objParameter));
export const GetMPProductDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetMPProductDetails', JSON.stringify(objParameter));

// MP Inventory
export const GetMPInventory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/MarketPlace/GetMPInventory', JSON.stringify(objParameter));
export const AddEditMPInventory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/MarketPlace/AddEditMPInventory', JSON.stringify(objParameter));

// invoice
export const GetInvoices = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Order/GetInvoices', JSON.stringify(objParameter));
export const GetInvoiceByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Order/GetInvoiceByID', JSON.stringify(objParameter));
export const DeleteInvoice = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Order/DeleteInvoice', JSON.stringify(objParameter));
export const AddEditInvoice = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Order/AddEditInvoice', JSON.stringify(objParameter));
export const GetInvoicingDummyDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetInvoicingDummyDetails', JSON.stringify(objParameter));
export const AddRevokeFunds = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/User/AddRevokeFunds', JSON.stringify(objParameter));

// Invoice Categories
export const GetInvoiceCategoryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Order/GetInvoiceCategoryForDropdown', JSON.stringify(objParameter));
export const AddEditInvoiceCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Order/AddEditInvoiceCategory', JSON.stringify(objParameter));
export const DeleteInvoiceCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Order/DeleteInvoiceCategory', JSON.stringify(objParameter));
export const GetInvoiceCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Order/GetInvoiceCategories', JSON.stringify(objParameter));
export const GetInvoiceCategoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Order/GetInvoiceCategoryByID', JSON.stringify(objParameter));

// Co-Op
export const GetLocationCoOp = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/User/GetLocationCoOp', JSON.stringify(objParameter));
export const AddRevokeCoOp = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/User/AddRevokeCoOp', JSON.stringify(objParameter));

// FeedBack
export const AddEditFeedback = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/User/AddEditFeedback', JSON.stringify(objParameter));
export const GetFeedback = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/User/GetFeedback', JSON.stringify(objParameter));
export const GetFeedbackByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/User/GetFeedbackByID', JSON.stringify(objParameter));
export const DeleteFeedback = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/User/DeleteFeedback', JSON.stringify(objParameter));

// Requesthistory & status
export const GetInvoicingList = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/GetInvoicingList', JSON.stringify(objParameter));

// ArchiveEmail
export const ArchiveEmail = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/ArchiveEmail', JSON.stringify(objParameter));

// PlatForms
export const AddEditPlatform = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditPlatform', JSON.stringify(objParameter));
export const DeletePlatform = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeletePlatform', JSON.stringify(objParameter));
export const GetPlatformByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPlatformByID', JSON.stringify(objParameter));
export const GetPlatformForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPlatformForDropdown', JSON.stringify(objParameter));
export const GetPlatforms = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPlatforms', JSON.stringify(objParameter));

// Pixel Code
export const GetPixelTrackingCode = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/GetPixelTrackingCode', JSON.stringify(objParameter));
