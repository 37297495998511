import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import welcome_img from "../../../assets/images/Error500.png";

const RouteErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState(null);
    const [errorInfo, setErrorInfo] = useState(null);
    const navigate = useNavigate();

    // Catch global errors (JavaScript errors)
    useEffect(() => {
        const handleError = (event) => {
            setHasError(true);
            setError(event.error || 'An unknown error occurred');
            setErrorInfo(event.errorInfo || 'No additional info available');
        };

        window.addEventListener('error', handleError);

        const handleUnhandledRejection = (event) => {
            setHasError(true);
            setError(event.reason || 'Unhandled promise rejection');
            setErrorInfo('No additional info available');
        };

        window.addEventListener('unhandledrejection', handleUnhandledRejection);

        return () => {
            window.removeEventListener('error', handleError);
            window.removeEventListener('unhandledrejection', handleUnhandledRejection);
        };
    }, []);

    // Handle back button navigation and reload
    useEffect(() => {
        const handlePopState = () => {
            // Check if we should handle the back button click
            if (hasError) {
                window.location.reload(); // Reload the page
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [hasError]);


    if (hasError) {
        return (
            <section className="content-right-main">
                <Grid container direction="column" alignItems="center" className="main_tab_content">
                    <img src={welcome_img} alt="Error" style={{ marginTop: '0px', paddingTop: '0px' }} />
                    <h1>Oops!! Something went wrong.</h1>
                    <Typography style={{ color: '#0A0A0A', fontSize: '18px' }}>
                        Please try again later.
                    </Typography>
                </Grid>
            </section>
        );
    }

    return children;
};

export default RouteErrorBoundary;
